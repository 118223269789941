.it-header-center-wrapper {
  .it-header-center-content-wrapper {
    .it-brand-wrapper a {
      .it-brand-text {
        color: darken($primary, 8);
      }
    }
    .it-right-zone {
      .it-search-wrapper a.rounded-icon {
        background-color: #fff;
      }
    }
  }
}

.tertiary-menu {
  li.nav-item {
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      border-right: 1px solid #fff;
    }

    a {
      @media (min-width: #{map-get($grid-breakpoints, lg)}) {
        font-size: 0.778em;
      }

      .external-link {
        vertical-align: sub;
        width: 16px;
        height: 16px;
      }
    }
  }
}

$primary-h: 166;
$primary-s: 100;
$primary-b: 53;

$accent: #b30709 !default;

//Background
$light: #ebebeb;

//colors
$dark-gray: #3f4142;

//font
$textDefault: $dark-gray;

//Header Settings
$header-center-bg-color: $light;
$header-center-text-color: $textDefault;
$navigation-link-color-desk: $header-center-text-color;
$navigation-toggle-button-icon-color: $textDefault;

//footer
$footer-top-background: $dark-gray;
$footer-main-text-color: #fff;

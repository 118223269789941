.siteroot,
body.public-ui.contenttype-lrf {
  .block-editor-title,
  .documentFirstHeading,
  #briciole {
    display: none;
  }
}

body.subsite.subsite-root {
  &,
  .public-ui {
    .documentFirstHeading,
    #briciole {
      display: none;
    }
  }
}

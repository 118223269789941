body.section-login {
  form {
    .ui.raised.segments {
      .ui.segment.form {
        &:before {
          content: '*Si prega di utilizzare il proprio nome utente con estensione @ospfe.it o @ausl.fe.it';
          display: block;
          font-size: 0.8rem;
          font-style: italic;
          text-align: center;
        }
      }
    }
  }
}
